<template>
    <section data-bs-version="5.1" class="menu cid-s48OLK6784" once="menu" id="menu1-h">

        <nav class="navbar navbar-dropdown navbar-fixed-top navbar-expand-lg">
            <div class="container">
                <div class="navbar-brand">
                    <span class="navbar-logo">
                        <a href="https://wnewer.com" target="_blank">
                            <img src="assets/images/wnewer-technology-358x128.png" alt="WNewer Technology"
                                style="height: 3.8rem;">
                        </a>
                    </span>

                </div>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-bs-toggle="collapse"
                    data-target="#navbarSupportedContent" data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <div class="hamburger">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav nav-dropdown" data-app-modern-menu="true">
                        <li class="nav-item"><a class="nav-link link text-black text-primary display-4"
                                href="index.html#content4-m">Sobre</a></li>
                        <li class="nav-item"><a class="nav-link link text-black text-primary display-4"
                                href="index.html#contacts2-s">Contatos</a></li>
                    </ul>

                    <div class="navbar-buttons mbr-section-btn"><a class="btn btn-primary display-4"
                            href="index.html#form5-q">ENTRAR EM CONTATO</a></div>
                </div>
            </div>
        </nav>

    </section>
    <section data-bs-version="5.1" class="header6 cid-sO0eO6VPqP mbr-fullscreen" id="header6-k">



        <div class="mbr-overlay" style="opacity: 0.6; background-color: rgb(0, 0, 0);"></div>

        <div class="align-center container">
            <div class="row justify-content-center">
                <div class="col-12 col-lg-9">
                    <h1 class="mbr-section-title mbr-fonts-style mbr-white mb-3 display-1"><strong>Bem-vindo(a) à
                        </strong><br><strong>WNewer Technology</strong></h1>

                    <p class="mbr-text mbr-white mbr-fonts-style display-7">Sistemas com tecnologia de ponta para sua
                        empresa crescer.</p>

                </div>
            </div>
        </div>
    </section>
    <section data-bs-version="5.1" class="content4 cid-sO0gy4ttyE" id="content4-m">


        <div class="container">
            <div class="row justify-content-center">
                <div class="title col-md-12 col-lg-10">
                    <h3 class="mbr-section-title mbr-fonts-style align-center mb-4 display-2">
                        <strong>Levamos tecnologia para sua empresa</strong>
                    </h3>


                </div>
            </div>
        </div>
    </section>
    <section data-bs-version="5.1" class="content5 cid-sO0gztoYio" id="content5-n">

        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-12 col-lg-10">


                    <p class="mbr-text mbr-fonts-style display-7">
                        Nosso principal objetivo é levar tecnologia de ponta de forma simples, rápida e eficiente para
                        sua empresa. Acreditamos que com a tecnologia certa, sua empresa pode crescer de forma lucrativa
                        e estável, tudo isso com confiabilidade e segurança.</p>
                </div>
            </div>
        </div>
    </section>
    <section data-bs-version="5.1" class="features1 cid-sO0giAAatP" id="features1-l">



        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-9">
                    <h3 class="mbr-section-title mbr-fonts-style align-center mb-0 display-2">
                        <strong>Nossos principais produtos</strong>
                    </h3>

                </div>
            </div>
            <div class="row">
                <div class="card col-12 col-md-6 col-lg-3">
                    <div class="card-wrapper">
                        <div class="card-box align-center">
                            <div class="iconfont-wrapper">
                                <span class="mbr-iconfont mbri-rocket"></span>
                            </div>
                            <h5 class="card-title mbr-fonts-style display-7"><strong>Wise Control</strong></h5>
                            <p class="card-text mbr-fonts-style display-7">Sistema de gestão empresarial focado em
                                gestão de vendas, gestão financeira, gestão de atendimentos (WhatsApp) e gestão de
                                estoque. Através dele é possível ter uma visão macro de sua empresa, tendo todo o
                                controle na palma de sua mão.</p>
                        </div>
                    </div>
                </div>
                <div class="card col-12 col-md-6 col-lg-3">
                    <div class="card-wrapper">
                        <div class="card-box align-center">
                            <div class="iconfont-wrapper">
                                <span class="mbr-iconfont mbri-timer"></span>
                            </div>
                            <h5 class="card-title mbr-fonts-style display-7"><strong>Connect View</strong></h5>
                            <p class="card-text mbr-fonts-style display-7">Sistema de gestão de redes e equipes
                                técnicas. É focado em empresas que desejam gerenciar suas redes e equipes técnicas de
                                forma simples, rápida e intuitiva. Através dele você saberá tudo que está acontecendo em
                                sua rede e poderá tomar as melhores decisões sempre que preciso.</p>
                        </div>
                    </div>
                </div>
                <div class="card col-12 col-md-6 col-lg-3">
                    <div class="card-wrapper">
                        <div class="card-box align-center">
                            <div class="iconfont-wrapper">
                                <span class="mbr-iconfont mbri-hearth"></span>
                            </div>
                            <h5 class="card-title mbr-fonts-style display-7"><strong>Wise Doctor</strong></h5>
                            <p class="card-text mbr-fonts-style display-7">Sistema para gestão de clínicas e
                                consultórios médicos. Trás recursos como prontuário eletrônico, gestão de atendimentos e
                                exames também de forma simples e intuitiva. Controle sua agenda, confirme horários e
                                tenha todas as informações de seu consultório ou clínica médica na palma de sua mão.</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
    <section data-bs-version="5.1" class="form5 cid-sO0hbkMTVw" id="form5-q">
        <div class="mbr-overlay"></div>
        <div class="container-fluid">
            <div class="mbr-section-head">
                <h3 class="mbr-section-title mbr-fonts-style align-center mb-0 display-2">
                    <strong>Entre em contato e saiba mais</strong>
                </h3>
                <h4 class="mbr-section-subtitle mbr-fonts-style align-center mb-0 mt-2 display-5">Converse com nossos
                    consultores e tire todas as suas dúvidas
                </h4>
            </div>
            <div class="row justify-content-center mt-4">
                <div class="col-lg-8 mx-auto mbr-form" data-form-type="formoid">
                    <form action="https://mobirise.eu/" method="POST" class="mbr-form form-with-styler"
                        data-form-title="Form Name"><input type="hidden" name="email" data-form-email="true"
                            value="RBuYbKf9cqDz/ocrdWb4Sn81owmB0gvMktmu//cPstgef2ZE6FJVIGyF3NSpnrxifaVBhTrBcOMxfLeu51R7JwH/WwknpaGuQY8HrT2ae/DYKewCzo4r4nZximk3R6CB">
                        <div class="row">
                            <div hidden="hidden" data-form-alert="" class="alert alert-success col-12">
                                Obrigado pelo contato.
                                Um de nossos consultores irá entrar em contato com você.
                            </div>
                            <div hidden="hidden" data-form-alert-danger="" class="alert alert-danger col-12">
                                Oops...! Ocorreu um problema. Tente novamente ou entre em contato por telefone.
                            </div>
                        </div>
                        <div class="dragArea row">
                            <div class="col-md col-sm-12 form-group mb-3" data-for="name">
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Nome"
                                    data-form-field="name"
                                    class="form-control"
                                    value=""
                                    id="name-form5-q"
                                />
                            </div>
                            <div class="col-md col-sm-12 form-group mb-3" data-for="email">
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="E-mail"
                                    data-form-field="email"
                                    class="form-control"
                                    value=""
                                    id="email-form5-q"
                                />
                            </div>
                            <div class="col-12 form-group mb-3" data-for="tel">
                                <input
                                    type="tel"
                                    name="tel"
                                    placeholder="Telefone"
                                    data-form-field="url"
                                    class="form-control"
                                    value=""
                                    id="url-form5-q"
                                />
                            </div>
                            <div class="col-12 form-group mb-3" data-for="textarea">
                                <textarea
                                    name="textarea"
                                    placeholder="Mensagem"
                                    data-form-field="textarea"
                                    class="form-control"
                                    id="textarea-form5-q"
                                ></textarea>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 align-center mbr-section-btn">
                                <button type="submit" class="btn btn-info display-4">ENVIAR AGORA</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    <section data-bs-version="5.1" class="contacts2 cid-sO0nz8RgnO" id="contacts2-s">
        <div class="container">
            <div class="mbr-section-head">
                <h3 class="mbr-section-title mbr-fonts-style align-center mb-0 display-2">
                    <strong>Entre em contato</strong>
                </h3>
            </div>
            <div class="row justify-content-center mt-4">
                <div class="card col-12 col-md-6">
                    <div class="card-wrapper">
                        <div class="image-wrapper">
                            <span class="mbr-iconfont socicon-whatsapp socicon"></span>
                        </div>
                        <div class="text-wrapper">
                            <h6 class="card-title mbr-fonts-style mb-1 display-5">
                                <strong>WhatsApp</strong>
                            </h6>
                            <p class="mbr-text mbr-fonts-style display-7"><a href="https://wa.me/5531998201057"
                                    class="text-primary">Clique para coversar</a></p>
                        </div>
                    </div>
                </div>
                <div class="card col-12 col-md-6">
                    <div class="card-wrapper">
                        <div class="image-wrapper">
                            <span class="mbr-iconfont mobi-mbri-phone mobi-mbri"></span>
                        </div>
                        <div class="text-wrapper">
                            <h6 class="card-title mbr-fonts-style mb-1 display-5">
                                <strong>Telefone</strong>
                            </h6>
                            <p class="mbr-text mbr-fonts-style display-7"><a href="tel:+553126268097"
                                    class="text-primary">Clique para ligar (31) 2626-8097</a></p>
                        </div>
                    </div>
                </div>
                <div class="card col-12 col-md-6">
                    <div class="card-wrapper">
                        <div class="image-wrapper">
                            <span class="mbr-iconfont mbrib-letter"></span>
                        </div>
                        <div class="text-wrapper">
                            <h6 class="card-title mbr-fonts-style mb-1 display-5">
                                <strong>Email</strong>
                            </h6>
                            <p class="mbr-text mbr-fonts-style display-7"><a href="mailto:contato@wnewer.com"
                                    class="text-primary">contato@wnewer.com</a></p>
                        </div>
                    </div>
                </div>
                <div class="card col-12 col-md-6">
                    <div class="card-wrapper">
                        <div class="image-wrapper">
                            <span class="mbr-iconfont mobi-mbri-chat mobi-mbri"></span>
                        </div>
                        <div class="text-wrapper">
                            <h6 class="card-title mbr-fonts-style mb-1 display-5">
                                <strong>Envie uma mensagem</strong>
                            </h6>
                            <p class="mbr-text mbr-fonts-style display-7">
                                <a href="index.html#form5-q" class="text-primary">Clique para enviar</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section data-bs-version="5.1" class="footer6 cid-sO0h1f3D86" once="footers" id="footer6-p">
        <div class="container">
            <div class="row content mbr-white">
                <div class="col-12 col-md-3 mbr-fonts-style display-7">
                    <h5 class="mbr-section-subtitle mbr-fonts-style mb-2 display-7">
                        <strong>Endereço</strong>
                    </h5>
                    <p class="mbr-text mbr-fonts-style display-7">Av. 1° de Junho, 420 sala 1117. Centro, Divinópolis -
                        MG</p> <br>
                    <h5 class="mbr-section-subtitle mbr-fonts-style mb-2 mt-4 display-7">
                        <strong>Contatos</strong>
                    </h5>
                    <p class="mbr-text mbr-fonts-style mb-4 display-7">
                        Email: <a href="mailto:contato@wnewer.com" class="text-primary">contato@wnewer.com</a> <br>
                        Telefone: <a href="tel:+553126268097" class="text-primary">+55 (31) 2626-8097</a><br>
                        WhatsApp: <a href="https://wa.me/5537998201057" class="text-primary">+55 (37) 9 9820-1057</a>
                    </p>
                </div>
                <div class="col-12 col-md-3 mbr-fonts-style display-7">
                    <h5 class="mbr-section-subtitle mbr-fonts-style mb-2 display-7"></h5>
                    <ul class="list mbr-fonts-style mb-4 display-4">
                        <li class="mbr-text item-wrap"><br></li>
                    </ul>
                    <h5 class="mbr-section-subtitle mbr-fonts-style mb-2 mt-5 display-7"></h5>
                    <p class="mbr-text mbr-fonts-style mb-4 display-7"></p>
                </div>
                <div class="col-12 col-md-6">
                    <div class="google-map">
                      <iframe
                        frameborder="0"
                        style="border:0"
                        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDk89J4FSunMF33ruMVWJaJht_Ro0kvoXs&amp;q=Av Primeiro de Junho 420, Divinópolis MG"
                        allowfullscreen=""
                      ></iframe>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="social-list align-left">
                        <div class="soc-item">
                            <a href="https://wa.me/5537998201057">
                                <span class="mbr-iconfont mbr-iconfont-social socicon-whatsapp socicon"></span>
                            </a>
                        </div>
                        <div class="soc-item">
                            <a href="https://www.instagram.com/wnewer_/" target="_blank">
                                <span class="mbr-iconfont mbr-iconfont-social socicon-instagram socicon"></span>
                            </a>
                        </div>




                    </div>
                </div>
            </div>
            <div class="footer-lower">
                <div class="media-container-row">
                    <div class="col-sm-12">
                        <hr>
                    </div>
                </div>
                <div class="col-sm-12 copyright pl-0">
                    <p class="mbr-text mbr-fonts-style mbr-white display-7">
                        © Copyright 2023 <a href="https://wnewer.com" class="text-primary" target="_blank">WNewer
                            Technology</a> - Todos os direitos reservados</p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
  export default {
    name: 'App'
  }
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
  }
</style>
